import React from 'react';
import classnames from 'classnames';
import { LazyImg } from 'src/hooks/useLazyLoad';
import { CustomLink } from 'src/components/CustomLink/CustomLink';
import Slider from 'react-slick';
import { Arrow } from 'src/elements/Carousel/settings';
import { ImageAndText } from 'src/elements/ImageAndText/ImageAndText';

import classes from './CarouselWithOrangeBlock.scss';

type CarouselWithOrangeBlockProps = {
    orangeTitle: string;
    title?: string;
    additionalBlock?: boolean;
    elements: {
        link: string;
        title: string;
        image: string;
    }[];
    withoutTitle?: boolean;
};

const carouselSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
    nextArrow: <Arrow direction="right" color="orange" />,
    prevArrow: <Arrow direction="left" />,
};

export const CarouselWithOrangeBlock: React.FC<CarouselWithOrangeBlockProps> = ({
    orangeTitle,
    title,
    additionalBlock = false,
    elements,
    withoutTitle = false,
}) => (
    <>
        {title && <h3 className={classes.title}>{title}</h3>}
        <div className={classes.categories}>
            <div className={classes.categoryDesktop}>
                <Slider {...carouselSettings}>
                    <div className={classnames(classes.categoryBlock, classes.orange)}>
                        <p>{orangeTitle}</p>
                    </div>
                    {elements.map((element) =>
                        withoutTitle ? (
                            <ImageAndText
                                link={element.link}
                                image={element.image}
                                paddingFullWithoutBottom={Boolean(element.title)}
                                withoutBackground
                                fullHeight
                            />
                        ) : (
                            <CustomLink link={element.link} className={classes.categoryBlock} key={element.title}>
                                <p>
                                    <span>{element.title}</span>
                                    <img src="../../../static/icons/v-right_orange.svg" />
                                </p>
                                <LazyImg className={classes.image} src={element.image} alt={element.title} />
                            </CustomLink>
                        ),
                    )}
                </Slider>
            </div>
            <div className={classes.categoryMobile}>
                {elements.map((element) => (
                    <CustomLink link={element.link} className={classes.categoryBlock} key={element.title}>
                        <div className={classes.categoryBlockContent}>
                            <LazyImg className={classes.image} src={element.image} alt={element.title} />
                        </div>
                        <p>{element.title}</p>
                    </CustomLink>
                ))}
                {additionalBlock && (
                    <>
                        <CustomLink link="/new-in" className={classes.categoryBlockAdditional}>
                            <div className={classnames(classes.categoryBlockContent, classes.orange)}>
                                <p>New Arrivals</p>
                            </div>
                            <p>New Arrivals</p>
                        </CustomLink>
                        <CustomLink link="/best-sellers" className={classes.categoryBlockAdditional}>
                            <div className={classnames(classes.categoryBlockContent, classes.green)}>
                                <LazyImg
                                    className={classes.image}
                                    src={require('../../../static/img/logo-header-mobile.svg')}
                                    alt="logo-header-mobile"
                                />
                                <p>Essentials</p>
                            </div>
                            <p>Essentials</p>
                        </CustomLink>
                    </>
                )}
            </div>
        </div>
    </>
);
